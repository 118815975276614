import "../general/ErrorOverlay";

import { StrictMode, useEffect } from "react";
import PropTypes from "prop-types";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router";

import { configureStore } from "../../store/configureStore";

// Hydrate store with preload state
const { reactPreloadState = null } = window.__PRELOADED_STATE__ || {};
if (!reactPreloadState) {
	throw new Error(
		"Missing preloaded Redux state. This likely indicates a server-side rendering issue.",
	);
}

const store = configureStore(reactPreloadState);

export const ClientApp = ({ children }) => {
	useEffect(() => {
		// Remove Emotion server styles
		const emotionServerStyles = document.querySelector("#emotion-server-side");
		if (emotionServerStyles) {
			emotionServerStyles.parentElement.removeChild(emotionServerStyles);
		}

		// Remove preload state from window
		const preloadState = document.querySelector("#preloadState");
		// in the HMR case, preload state might be null
		if (preloadState !== null) {
			preloadState.parentElement.removeChild(preloadState);
		}
	}, []);

	return (
		<StrictMode>
			<Provider store={store}>
				<BrowserRouter>{children}</BrowserRouter>
			</Provider>
		</StrictMode>
	);
};

ClientApp.propTypes = {
	children: PropTypes.node.isRequired,
};
