import { useContext } from "react";
import { FVY_DOMAIN, SITE } from "@/config/constants";
import {
	CELL_PHONE_PLANS_PATH,
	FLOWER_DELIVERY_PATH,
	PET_FOOD_DELIVERY_PATH,
	WEBSITE_BUILDERS_PATH,
} from "@/config/site/favy";
import { FINAL_EXPENSE_INSURANCE_PATH } from "@/config/site/insuranceranked";
import { BUSINESS_BANKING_PATH } from "@/config/site/thecreditreview";
import * as selectors from "@/selectors";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { prop } from "ramda";
import { useSelector } from "react-redux";
import tw from "twin.macro";

import { CompanyContext } from "../CompanyList/CompanyContext";

const styles = {
	serviceFlag: {
		legacy: css`
			position: absolute;
			top: 9px;
			left: -3px;
			display: flex;
		`,
		revamped: css`
			position: absolute;
			top: 9px;
			left: -5px;
			display: flex;
		`,
		favy: css`
			display: flex;
			flex-direction: column;
			justify-content: center;

			@media (max-width: 991px) {
				flex-direction: initial;
				align-items: center;
			}
		`,
	},
	highlight: {
		legacy: css`
			width: 44px;
			height: 44px;
			background-color: #1d8ce0;
			border: 1px solid #1d8ce0;
			border-radius: 0 3px 3px 0;
			box-shadow: 0 3px 8px rgba(58, 169, 191, 0.33);
			z-index: inherit;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			color: #fff;
			font-size: 1.5rem;
			font-family: AvenirNextLTPro-Demi;
		`,
		revamped: css`
			width: 28px;
			height: 28px;
			background-color: #1d8ce0;
			border: 1px solid #1d8ce0;
			border-radius: 3px;
			box-shadow: 1px 3px 3px lightgrey;
			z-index: inherit;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			color: #fff;
			font-size: 14px;
			font-family: AvenirNextLTPro-Demi;

			@media (max-width: 580px) {
				width: 0;
				height: 0;
				font-size: 12px;
				padding: 12px 11px 10px;
				border-radius: 3px;
			}
		`,
		[FVY_DOMAIN]: css`
			display: flex;
			position: relative;
			align-items: center;
			justify-content: center;
			left: 0;
			width: 32px;
			height: 32px;
			border-radius: 50%;
			background-color: #616096;
			color: white;

			@media (max-width: 992px) {
				height: 24px;
				width: 24px;
			}
		`,
	},
	awardText: {
		legacy: css`
			background-color: #333742;
			margin-left: -2px;
			display: flex;
			align-items: center;
			justify-content: center;
			line-height: 1;
			color: #f4f7f8;
			padding-left: 10px;
			padding-top: 3px;
			width: 80px;
			max-width: 70px;
			font-weight: 600;
			font-size: 12px;
			text-transform: uppercase;
			font-family: AvenirNextLTPro-Demi;

			@media (max-width: 500px) {
				display: none;
			}
		`,
		hide: css`
			display: none;
		`,
		topCompanyRevamped: [
			tw`flex justify-center items-center ml-1 py-2 pl-3 text-white`,
			css`
				height: 28px;
				border-radius: 2px 0 0 2px;
				background-color: #1e8ce0;
				font-weight: 600;
				font-size: 13px;
				line-height: 15px;
				box-shadow: 1px 3px 3px lightgrey;

				:after {
					position: relative;
					width: 22px;
					left: 6px;
					content: "";
					border-right: 15px solid white;
					border-top: 14px solid #1e8ce0;
					border-bottom: 14px solid #1e8ce0;
				}

				@media (max-width: 580px) {
					height: 24px;
					font-size: 12px;

					:after {
						border-right: 13px solid white;
						border-top: 12px solid #1e8ce0;
						border-bottom: 12px solid #1e8ce0;
					}
				}

				@media (max-width: 345px) {
					font-size: 10px;
				}
			`,
		],
		[FVY_DOMAIN]: [
			css`
				position: absolute;
				top: 5px;
				margin-left: 0px;
				line-height: 16px;
				font-size: 16px;
				box-shadow: none;

				@media (max-width: 991px) {
					position: relative;
					margin-left: 0.5rem;
					top: 0;
				}

				border-radius: 7px 0px 0px 7px;

				:after {
					border-top: 14px solid #e1255b;
					border-bottom: 14px solid #e1255b;
				}
				@media (max-width: 580px) {
					font-size: 14px;

					:after {
						border-top: 12px solid #e1255b;
						border-bottom: 12px solid #e1255b;
					}
				}
			`,
			tw`bg-fvy-home-pink`,
		],
		software: css`
			background: linear-gradient(89.93deg, #4a9fd9 33.55%, #87e4b9 145.93%);
			${tw`!text-sm font-medium pr-[6px] rounded-none !pl-2 !h-[22px] after:border-t-transparent after:border-b-transparent after:border-t-[14px] after:border-b-[14px]`}
		`,
		[PET_FOOD_DELIVERY_PATH]: tw`after:left[0px] !pl-2`,
	},
	awardTriangle: {
		hide: css`
			display: none;
		`,
		legacy: css`
			width: 0;
			height: 0;
			border-top: 22px solid transparent;
			border-left: 16px solid #333742;
			border-bottom: 22px solid transparent;

			@media (max-width: 500px) {
				display: none;
			}
		`,
	},
};

const isLikeFavyDesign = [FVY_DOMAIN].includes(SITE);

const ServiceFlag = (props) => {
	const { company } = useContext(CompanyContext);
	const { companyIndex, variant } = props;
	const companyName = useSelector((state) =>
		selectors.getCompanyName(state, { company }),
	);
	const awardText = useSelector((state) =>
		selectors.getAwardText(state, { company }),
	);
	const vertical = useSelector(selectors.getVertical);
	const isServiceBlockLegacyStyled = useSelector(
		selectors.getIsServiceBlockLegacyStyled,
	);
	// TODO: could refactor the way that we select company attributes to avoid having to merge attributes from context and props
	const revampedAwardText = useSelector((state) =>
		selectors.getRevampedAwardText(state, { ...props, company }),
	);
	const usesSoftwareDesign = useSelector(selectors.getUsesSoftwareDesign);
	const usesNewSoftwareDesign = useSelector(selectors.getUsesNewSoftwareDesign);
	const isProductListingPage = useSelector(selectors.getIsProductListingPage);
	// eslint-disable-next-line no-magic-numbers
	const isAllowedToRender = useSelector((state) =>
		selectors.getIsAwardTextAllowed(state, props),
	);
	const usesRevampedAwardText =
		vertical === WEBSITE_BUILDERS_PATH
			? revampedAwardText
			: revampedAwardText && isAllowedToRender;

	// A/B testing variables
	const businessBankingABTest = "Sign Up in 60 Seconds";
	const finalExpenseAwardText = "Our Top Choice";
	const isFirstCompany = companyIndex === 1;
	const renderFinalExpenseAwardText =
		isFirstCompany && vertical === FINAL_EXPENSE_INSURANCE_PATH;
	const renderABTestVariant = isFirstCompany && variant;

	const awardTriangle = [
		<span
			css={[
				isServiceBlockLegacyStyled && awardText
					? styles.awardText.legacy
					: styles.awardText.hide,
				(usesRevampedAwardText ||
					renderABTestVariant ||
					renderFinalExpenseAwardText) && [
					styles.awardText.topCompanyRevamped,
					prop(SITE, styles.awardText),
					prop(vertical, styles.awardText),
				],
				(usesSoftwareDesign ||
					isProductListingPage ||
					[FLOWER_DELIVERY_PATH].includes(vertical)) &&
					styles.awardText.software,
				(usesNewSoftwareDesign || isProductListingPage) &&
					css`
						left: 0px;
						top: 14px;
					`,
			]}
			key={companyIndex}
		>
			{awardText || (renderFinalExpenseAwardText && finalExpenseAwardText)}
			{renderABTestVariant &&
				vertical === BUSINESS_BANKING_PATH &&
				businessBankingABTest}
		</span>,
		<div
			key={companyName}
			css={[
				isServiceBlockLegacyStyled && awardText
					? styles.awardTriangle.legacy
					: styles.awardTriangle.hide,
			]}
		/>,
	];

	return (
		<div
			css={[
				isLikeFavyDesign
					? [
							styles.serviceFlag.favy,
							(usesNewSoftwareDesign ||
								isProductListingPage ||
								[FLOWER_DELIVERY_PATH, CELL_PHONE_PLANS_PATH].includes(
									vertical,
								)) &&
								tw`!ml-4`,
						]
					: [
							isServiceBlockLegacyStyled
								? styles.serviceFlag.legacy
								: styles.serviceFlag.revamped,
						],
			]}
		>
			<div
				css={[
					isLikeFavyDesign
						? styles.highlight.favy
						: [
								isServiceBlockLegacyStyled
									? styles.highlight.legacy
									: styles.highlight.revamped,
								prop(SITE, styles.highlight),
							],
					prop(SITE, styles.highlight),
				]}
			>
				{companyIndex}
			</div>
			{(awardText && awardTriangle) ||
				(renderFinalExpenseAwardText && finalExpenseAwardText && awardTriangle)}
			{renderABTestVariant &&
				vertical === BUSINESS_BANKING_PATH &&
				awardTriangle}
		</div>
	);
};

export default ServiceFlag;

ServiceFlag.propTypes = {
	companyIndex: PropTypes.number.isRequired,
	variant: PropTypes.string,
};
