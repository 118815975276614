import { useSelector } from "react-redux";

import {
	FVY_DOMAIN,
	IR_DOMAIN,
	SITE,
	TCR_DOMAIN,
} from "../../../config/constants";
import { HAIR_GROWTH_PATH } from "../../../config/site/favy";
import {
	DISABILITY_INSURANCE_DEFAULT_PATH,
	IR_LIFE_INSURANCE_DEFAULT_PATH,
} from "../../../config/site/insuranceranked";
import { PERSONAL_LOANS_DEFAULT_PATH } from "../../../config/site/thecreditreview";
import { getVertical } from "../../selectors";
import { DisabilityInsuranceFilter } from "./DisabilityInsuranceFilter";
import { HairGrowthFilter } from "./HairGrowthFilter";
import { LifeInsuranceFilter } from "./LifeInsuranceFilter";
import { PersonalLoansFilter } from "./PersonalLoansFilter";

export const IndividualVerticalFilteringContainer = () => {
	const vertical = useSelector(getVertical);

	// This can go into vertical config like grid.js
	if (SITE === FVY_DOMAIN) {
		if (vertical === HAIR_GROWTH_PATH) return <HairGrowthFilter />;

		return null;
	}

	if (SITE === IR_DOMAIN) {
		if (vertical === IR_LIFE_INSURANCE_DEFAULT_PATH)
			return <LifeInsuranceFilter />;

		if (vertical === DISABILITY_INSURANCE_DEFAULT_PATH)
			return <DisabilityInsuranceFilter />;
	}

	if (SITE === TCR_DOMAIN) {
		if (vertical === PERSONAL_LOANS_DEFAULT_PATH)
			return <PersonalLoansFilter />;
	}

	return null;
};
