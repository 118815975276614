import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { path } from "ramda";
import { ModalOverlay } from "react-aria-components";
import { useSelector } from "react-redux";

import { logError } from "../../helpers/logError";
import {
	getTargetedSlug,
	getVertical,
	sortedCompaniesSelector,
} from "../../selectors";
import { CompanyContext } from "../CompanyList/CompanyContext";

const DELAY_MS = 5000;

export const BaseExitModalProvider = ({
	vertical,
	topCompanySlug,
	children,
	...props
}) => (
	<ExitModalVerticalCheck vertical={vertical}>
		<ExitModalCompanyProvider topCompanySlug={topCompanySlug}>
			<ExitModalOverlay {...props}>{children}</ExitModalOverlay>
		</ExitModalCompanyProvider>
	</ExitModalVerticalCheck>
);

BaseExitModalProvider.propTypes = {
	vertical: PropTypes.string.isRequired,
	topCompanySlug: PropTypes.string.isRequired,
	delayMs: PropTypes.number,
	children: PropTypes.node.isRequired,
};

const ExitModalVerticalCheck = ({ vertical, children }) => {
	const currentVertical = useSelector(getVertical);
	const targetedSlug = useSelector(getTargetedSlug);

	if (targetedSlug == null) return null;
	if (vertical !== currentVertical) return null;

	return children;
};

ExitModalVerticalCheck.propTypes = {
	vertical: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
};

const ExitModalCompanyProvider = ({ topCompanySlug, children }) => {
	const companies = useSelector(sortedCompaniesSelector);

	const company = companies.find(
		(company) => company?.fields?.companySlug === topCompanySlug,
	);

	if (!company) return null;

	return (
		<CompanyContext.Provider
			key={path(["fields", "companyName"], company)}
			value={{ company: company, companyIndex: 1 }}
		>
			{children}
		</CompanyContext.Provider>
	);
};

ExitModalCompanyProvider.propTypes = {
	topCompanySlug: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
};

const ExitModalOverlay = ({ delayMs = DELAY_MS, children, ...props }) => {
	const [isOpen, setIsOpen] = useState(false);
	const hasOpened = useRef(false);

	useEffect(() => {
		if (!hasOpened.current) {
			const timer = setTimeout(() => {
				setIsOpen(true);
				hasOpened.current = true;
			}, delayMs);

			return () => clearTimeout(timer);
		}
	}, []);

	return (
		<ModalOverlay
			isOpen={isOpen}
			onOpenChange={setIsOpen}
			isDismissable
			data-testid="exit-modal"
			{...props}
		>
			{children}
		</ModalOverlay>
	);
};

ExitModalOverlay.propTypes = {
	delayMs: PropTypes.number,
	children: PropTypes.node.isRequired,
};

const MultiExitModalCompanyProvider = ({ vertical, modals, ...props }) => {
	const companies = useSelector(sortedCompaniesSelector);
	if (!companies?.length || !modals?.length) return null;

	// Find first matching modal based on company position
	const firstCompany = companies[0];
	const firstCompanySlug = firstCompany?.fields?.companySlug;
	const matchingModal = modals.find(
		(modal) => modal.companySlug === firstCompanySlug,
	);

	// If first company doesn't match any modal, log to Sentry and return null
	if (!matchingModal) {
		logError(
			`Vertical ${vertical} does not have an exit modal available for the top company (${firstCompanySlug})`,
		);

		return null;
	}

	return (
		<CompanyContext.Provider
			key={path(["fields", "companyName"], firstCompany)}
			value={{ company: firstCompany, companyIndex: 1 }}
		>
			<ExitModalOverlay {...props}>{matchingModal.component}</ExitModalOverlay>
		</CompanyContext.Provider>
	);
};

MultiExitModalCompanyProvider.propTypes = {
	vertical: PropTypes.string.isRequired,
	modals: PropTypes.arrayOf(
		PropTypes.shape({
			companySlug: PropTypes.string.isRequired,
			component: PropTypes.node.isRequired,
		}),
	).isRequired,
	delayMs: PropTypes.number,
};

export const MultiExitModalProvider = ({ vertical, modals, ...props }) => (
	<ExitModalVerticalCheck vertical={vertical}>
		<MultiExitModalCompanyProvider
			vertical={vertical}
			modals={modals}
			{...props}
		/>
	</ExitModalVerticalCheck>
);

MultiExitModalProvider.propTypes = {
	vertical: PropTypes.string.isRequired,
	modals: PropTypes.arrayOf(
		PropTypes.shape({
			companySlug: PropTypes.string.isRequired,
			component: PropTypes.node.isRequired,
		}),
	).isRequired,
	delayMs: PropTypes.number,
};
