import { Component } from "react";
import * as constants from "@/config/constants";
import { shouldFilteringModalRender } from "@/helpers";
import * as selectors from "@/selectors";
import { setModalFilteringState } from "@/store/filtering/actions";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { default as ReactModal } from "react-modal2";
import { connect } from "react-redux";

const styles = {
	filteringModalWrapper: css`
		position: absolute;
		top: 50%;
		left: 50%;
		-webkit-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		outline: none;

		@media (max-width: 580px) {
			width: 90%;
		}
	`,
	modalContent: css`
		min-width: 700px;
		position: fixed;
		background-color: #fff;
		-webkit-background-clip: padding-box;
		background-clip: padding-box;
		border: 1px solid #999;
		border: 1px solid rgba(0, 0, 0, 0.2);
		border-radius: 6px;
		outline: 0;
		-webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
		box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
		width: auto;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);

		@media (max-width: 580px) {
			width: 90%;
		}

		@media (max-width: 767px) {
			min-width: auto;
		}
	`,

	modalHeader: css`
		text-align: center;
		border-radius: 5px 5px 0 0;
		background-color: #8bc67b;
		background-image: url("/react-images/common/modalCoin.png");
		background-size: cover;
		padding: 30px;
		position: relative;
		min-height: 16.43px;
		border-bottom: 1px solid #e5e5e5;

		@media (max-width: 767px) {
			background-image: none;
			padding: 15px;
		}

		small {
			position: absolute;
			color: #fff;
			opacity: 0.7;
			font-size: 16px;
			font-weight: 700;
			bottom: 2px;
			left: 55%;
			width: auto;
			white-space: nowrap;
			-webkit-transform: translateX(-50%);
			transform: translateX(-50%);

			@media (max-width: 580px) {
				font-size: 13px;
			}
			@media (max-width: 767px) {
				position: static;
			}
		}

		h5 {
			font-size: 36px;
			font-weight: 400;
			text-align: left;
			line-height: 1;
			max-width: 420px;
			margin: 0 40px 0 auto;
			padding-bottom: 30px;
			color: #fff;
			font-family: Montserrat;
			letter-spacing: -0.01em;

			@media (max-width: 767px) {
				font-size: 20px;
				line-height: 1.2;
				font-weight: 600;
				margin: 0 auto;
				text-align: center;
				padding-bottom: 0;
			}
		}
	`,

	closeModalButton: css`
		opacity: 1;
		margin-top: -2px;

		span {
			position: absolute;
			top: 20px;
			right: 20px;
			background-image: url("/react-images/common/modalCloseIcon.svg");
			background-size: contain;
			background-position: left;
			width: 15px;
			height: 14px;

			@media (max-width: 767px) {
				top: 15px;
				right: 15px;
			}

			@media (max-width: 767px) {
				background-size: 13px;
				background-repeat: no-repeat;
				top: 15px;
				right: 10px;
			}
		}
	`,

	modalBody: css`
		position: relative;
		background-image:
			url("/react-images/common/arrowRight.png"),
			url("/react-images/common/arrowLeft.png");
		background-repeat: no-repeat;
		background-position:
			left -20px center,
			right -20px center;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 40px 30px;
		position: relative;

		@media (max-width: 767px) {
			padding: 20px 15px;
			background-image: none;
		}

		@media (max-width: 580px) {
			display: block;
			text-align: center;
		}
	`,

	modalOptions: css`
		position: relative;
		flex: 1;
		max-width: 125px;
		margin: 0 10px;
		border: 1px solid #d6dbdf;
		border-radius: 5px;
		letter-spacing: 1px;
		color: #333742;
		font-weight: 700;
		padding: 15px 20px 20px;
		line-height: normal;
		text-align: center;
		transition: background-color 0.25s ease;
		font-family: Montserrat;
		cursor: pointer;

		@media (max-width: 580px) {
			display: inline-block;
			width: 40%;
			padding: 10px 15px 15px;
			max-width: 100%;
			margin: 10px;
		}

		:after {
			position: absolute;
			content: "";
			width: 100%;
			height: 4px;
			background-color: transparent;
			bottom: 0;
			left: 0;
			border-radius: 0 0 3px 3px;
		}

		p {
			font-family: Montserrat;
			font-size: 20px;
			margin: 0;
			font-weight: 700;

			@media (max-width: 500px) {
				line-height: 1.46;
			}
		}

		span {
			font-size: 13px;
			font-weight: 700;
		}
	`,
	poor: css`
		:after {
			background-color: #f35e2e;
		}

		:hover {
			background-color: #f35e2e;
			border-color: #f35e2e;
			color: #fff;
		}
	`,
	fair: css`
		:after {
			background-color: #f79734;
		}
		:hover {
			background-color: #f79734;
			border-color: #f79734;
			color: #fff;
		}
	`,
	good: css`
		:after {
			background-color: #f7c54d;
		}

		:hover {
			background-color: #f7c54d;
			border-color: #f7c54d;
			color: #fff;
		}
	`,
	excellent: css`
		:after {
			background-color: #6dc355;
		}

		:hover {
			background-color: #6dc355;
			border-color: #6dc355;
			color: #fff;
		}
	`,

	modalFooter: css`
		color: #333;
		font-size: 13px;
		font-style: italic;
		line-height: 1.08;
		text-align: center;
		border: none;
		position: relative;
		padding: 15px;

		:before {
			width: 100%;
			position: absolute;
			content: "";
			height: 1px;
			background-color: #e0e1e1;
			top: 0;
			left: 50%;
			-webkit-transform: translateX(-50%);
			transform: translateX(-50%);
		}

		span {
			color: #333;
			font-size: 13px;
			font-style: italic;
			line-height: 1.08;
			text-align: center;
			border: none;
			position: relative;
		}

		@media (max-width: 580px) {
			padding: 10px;
		}
	`,
};

const mapDispatchToProps = {
	setModalFilteringState,
};

const mapStateToProps = (state) => ({
	isTransitionApplied: selectors.getIsTransitionApplied(state),
	vertical: selectors.getVertical(state),
	isTargetedPath: selectors.getIsTargetedPath(state),
	isModalUrlParamMatched: selectors.getIsModalUrlParamMatched(state),
});

export class FilteringModal extends Component {
	state = {
		hasModalBeenClosed: false,
	};

	applyModalFiltering = (event) => {
		const creditQuality = event.currentTarget.getAttribute(
			"data-filtering-value",
		);
		this.props.setModalFilteringState(
			{ ["creditQuality"]: creditQuality },
			this.props.vertical,
		);
		this.closeModal();
	};

	closeModal = () => {
		this.setState({ hasModalBeenClosed: true });
	};

	render() {
		const {
			isForceRendered,
			isTargetedPath,
			isModalUrlParamMatched,
			vertical,
		} = this.props;
		const { hasModalBeenClosed } = this.state;
		const isOpen = shouldFilteringModalRender(
			isForceRendered,
			isTargetedPath,
			hasModalBeenClosed,
			isModalUrlParamMatched,
			vertical,
		);
		return isOpen ? (
			<ReactModal
				css={styles.filteringModalWrapper}
				onClose={this.closeModal}
				closeOnBackdropClick={true}
				backdropStyles={{
					backgroundColor: "rgba(140,142,148,.3)",
					opacity: 1,
					transition: "opacity .15s ease-out",
					zIndex: 100,
					position: "fixed",
					width: "100%",
					height: "100%",
					top: 0,
					left: 0,
				}}
				closeOnEsc={true}
			>
				<div id="filteringModal" css={styles.modalContent}>
					<div css={styles.modalHeader}>
						<small>{`${constants.CURRENT_YEAR}'s best personal loans are just a click away`}</small>
						<h5 id="ModalLabel">{"What's your estimated credit score?"}</h5>
						<button
							css={styles.closeModalButton}
							onClick={this.closeModal}
							type="button"
							className="close"
							data-dismiss="modal"
							aria-label="Close"
						>
							<span aria-hidden="true"></span>
						</button>
					</div>
					<div css={styles.modalBody}>
						<a
							id="filteringModalPoorOption"
							css={[styles.modalOptions, styles.poor]}
							data-filtering-value={"Poor"}
							onClick={this.applyModalFiltering}
						>
							<p>POOR</p>
							<span>(300-579)</span>
						</a>
						<a
							css={[styles.modalOptions, styles.fair]}
							data-filtering-value={"Fair"}
							onClick={this.applyModalFiltering}
						>
							<p>FAIR</p>
							<span>(580-669)</span>
						</a>
						<a
							css={[styles.modalOptions, styles.good]}
							data-filtering-value={"Good"}
							onClick={this.applyModalFiltering}
						>
							<p>GOOD</p>
							<span>(670-739)</span>
						</a>
						<a
							css={[styles.modalOptions, styles.excellent]}
							data-filtering-value={"Excellent"}
							onClick={this.applyModalFiltering}
						>
							<p>GREAT</p>
							<span>(740-850)</span>
						</a>
					</div>
					<div css={styles.modalFooter}>
						<span>
							{
								"It's okay to guess! Estimating your credit score helps us show you more accurate results."
							}
						</span>
					</div>
				</div>
			</ReactModal>
		) : null;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(FilteringModal);

FilteringModal.propTypes = {
	appElement: PropTypes.string.isRequired,
	vertical: PropTypes.string.isRequired,
	isTargetedPath: PropTypes.bool.isRequired,
	isModalUrlParamMatched: PropTypes.bool.isRequired,
	setModalFilteringState: PropTypes.func.isRequired,
	filter: PropTypes.string,
	isForceRendered: PropTypes.bool,
};
