import { useCallback } from "react";
import PropTypes from "prop-types";
import queryString from "query-string";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router";

import {
	DEFAULT_PAGINATION_LABEL_NEXT,
	DEFAULT_PAGINATION_LABEL_PREVIOUS,
	SITE_INFORMATION,
} from "../../../config/constants";

export const Pagination = ({
	page,
	pageCount,
	count,
	partialQueryObjectForPaginationClicks,
	...props
}) => {
	const navigate = useNavigate();

	const handlePageClick = useCallback(
		(data) => {
			const selectedPage = data.selected + 1;
			const linkProperties = { page: selectedPage, count };
			const paginationSearch = `?${queryString.stringify({
				...linkProperties,
				...partialQueryObjectForPaginationClicks,
			})}`;

			navigate(`${paginationSearch}`);
		},
		[count, partialQueryObjectForPaginationClicks, navigate],
	);

	const buildLinks = useCallback(
		(selected) => `?page=${selected}&count=${count}`,
		[count],
	);

	return (
		<ReactPaginate
			previousLabel={
				SITE_INFORMATION.criticalOperationProperties?.paginationLabelText
					?.previous ?? DEFAULT_PAGINATION_LABEL_PREVIOUS
			}
			nextLabel={
				<span data-cy="pagination-next-button">
					{SITE_INFORMATION.criticalOperationProperties?.paginationLabelText
						?.next ?? DEFAULT_PAGINATION_LABEL_NEXT}
				</span>
			}
			breakLabel={"..."}
			breakClassName={"break-me"}
			disableInitialCallback={true}
			pageCount={pageCount}
			hrefBuilder={buildLinks}
			marginPagesDisplayed={2}
			/* ReactPaginate treats each page as an index, hence subtracting 1 */
			forcePage={page - 1}
			pageRangeDisplayed={5}
			onPageChange={handlePageClick}
			activeClassName={"active"}
			{...props}
		/>
	);
};

Pagination.propTypes = {
	page: PropTypes.number.isRequired,
	pageCount: PropTypes.number.isRequired,
	count: PropTypes.number.isRequired,
	partialQueryObjectForPaginationClicks: PropTypes.object.isRequired,
};
