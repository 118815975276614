import { Component, createRef } from "react";
import ServiceFlag from "@/components/ServiceBlock/ServiceFlag";
import * as constants from "@/config/constants";
import {
	CELL_PHONE_PLANS_PATH,
	FLOWER_DELIVERY_PATH,
} from "@/config/site/favy";
import { FINAL_EXPENSE_INSURANCE_PATH } from "@/config/site/insuranceranked";
import * as selectors from "@/selectors";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { path } from "ramda";
import { connect } from "react-redux";
import { Transition } from "react-transition-group";
import tw from "twin.macro";

import { CompanyContext } from "../CompanyList/CompanyContext";
import Coupon from "./Coupon";
import ServiceLeftLayout from "./ServiceLeft/ServiceLeftLayout";
import ServiceRightLayout from "./ServiceRight/ServiceRightLayout";

const styles = {
	legacy: css`
		margin-top: 1.125rem;
		background-color: white;
		border: 1px solid #d6dbdf;
		border-radius: 3px;
		box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07);
		position: relative;
		display: flex;

		@media (max-width: 991px) {
			flex-wrap: wrap;
		}

		@media (max-width: 767px) {
			max-width: 500px;
			margin-left: auto;
			margin-right: auto;
		}
	`,
	revamped: {
		baseStyles: css`
			box-shadow: none;
			margin-top: 1.125rem;
			border-left: none;
			border-right: none;
			border-top: none;
			border-bottom: 1px solid #f2f3f4;
			border-radius: 3px;
			background-color: white;
			position: relative;
			display: flex;

			@media (max-width: 991px) {
				flex-wrap: wrap;
			}

			@media (max-width: 767px) {
				max-width: 500px;
				margin-left: auto;
				margin-right: auto;
			}
		`,
		domainLevelOverrides: {
			[constants.IR_DOMAIN]: {
				allVerticals: css`
					margin-top: none;
				`,
			},
			[constants.FVY_DOMAIN]: {
				allVerticals: css`
					margin-top: 0;
					border-bottom: none;
					border-top: 1px solid rgb(229, 231, 235);

					@media (max-width: 992px) {
						padding-top: 16px;
					}
				`,
			},
		},
	},
	transitions: {
		entering: css`
			opacity: 1;
			background-color: #e9ffd7;
			transition: opacity 250ms ease-in-out;
		`,

		entered: css`
			opacity: 1;
			transition:
				opacity,
				background-color 250ms ease-in-out;
		`,
	},
	profCreditBorder: css`
		border: 2px solid coral;
	`,
	variant: {
		[1]: css`
			border: 2px solid #1d8ce0;
		`,
		[2]: css`
			border: 2px solid #6ac54e;
		`,
	},
};

const mapStateToProps = (state, ownProps) => ({
	isNotUserFiltered: selectors.getIsNotUserFiltered(state),
	vertical: selectors.getVertical(state),
	isServiceBlockLegacyStyled: selectors.getIsServiceBlockLegacyStyled(state),
	revampedAwardText: selectors.getRevampedAwardText(state, ownProps),
	companyName: selectors.getCompanyName(state, ownProps),
	companySlug: selectors.getCompanySlug(state, ownProps),
	usesSoftwareDesign: selectors.getUsesSoftwareDesign(state),
	usesNewSoftwareDesign: selectors.getUsesNewSoftwareDesign(state),
	usesNonSoftwareCoupon: selectors.getUsesNonSoftwareCoupon(state, ownProps),
	couponReplacesReviewCta: selectors.getCouponReplacesReviewCta(
		state,
		ownProps,
	),
	isProductListingPage: selectors.getIsProductListingPage(state, ownProps),
	usesSimplifiedDesign: selectors.getUsesSimplifiedDesign(state, ownProps),
});

export class ServiceBlockLayout extends Component {
	constructor(props) {
		super(props);
		const { innerWidth, usesSoftwareDesign } = this.props;
		const targetWidth = usesSoftwareDesign
			? constants.MOBILE_WIDTH
			: constants.TABLET_WIDTH;
		const serviceContentSelectorStyle =
			innerWidth > targetWidth ? "none" : "flex";
		const serviceContentDisplayStyle =
			innerWidth > targetWidth ? "block" : "none";

		this.state = {
			innerWidth,
			serviceContentSelectorStyle,
			serviceContentDisplayStyle,
		};
		this.nodeRef = createRef();
	}

	static getDerivedStateFromProps(props, prevState) {
		const { innerWidth, usesSoftwareDesign } = props;
		let newState = prevState;
		const targetWidth = usesSoftwareDesign
			? constants.MOBILE_WIDTH
			: constants.TABLET_WIDTH;
		newState.serviceContentSelectorStyle =
			innerWidth > targetWidth ? "none" : prevState.serviceContentSelectorStyle;
		newState.serviceContentDisplayStyle =
			innerWidth > targetWidth ? "block" : prevState.serviceContentDisplayStyle;
		return newState;
	}

	// Displays service content then hides service content selector
	expandServiceContent = () => {
		this.setState({
			serviceContentDisplayStyle: "block",
			serviceContentSelectorStyle: "none",
		});
	};

	render() {
		const {
			vertical,
			company,
			companySlug,
			isNotUserFiltered,
			isServiceBlockLegacyStyled,
			variant,
			revampedAwardText,
			companyName,
			usesNewSoftwareDesign,
			usesNonSoftwareCoupon,
			isFeatured,
			couponReplacesReviewCta,
			isProductListingPage,
			usesSimplifiedDesign,
		} = this.props;
		const companyIndex = this.props.companyIndex + 1;
		const serviceBlockLayoutStyles = isServiceBlockLegacyStyled
			? [styles.legacy]
			: [
					styles.revamped.baseStyles,
					path(
						[
							"revamped",
							"domainLevelOverrides",
							constants.SITE,
							"allVerticals",
						],
						styles,
					),
					path(
						["revamped", "domainLevelOverrides", constants.SITE, vertical],
						styles,
					),
				];
		const isFirstCompany = companyIndex === 1;

		return (
			<CompanyContext.Provider value={{ company, companyIndex }}>
				<Transition
					nodeRef={this.nodeRef}
					in
					appear={!isNotUserFiltered}
					timeout={constants.FILTERING_TRANSITION_DURATION}
					unmountOnExit
				>
					{(state) => (
						<div
							css={[
								...serviceBlockLayoutStyles,
								path(["transitions", state], styles),
								isFirstCompany && constants.SITE === constants.PC_DOMAIN
									? variant
										? styles.variant[variant]
										: styles.profCreditBorder
									: null,
								(revampedAwardText ||
									(isFirstCompany &&
										vertical === "business-banking" &&
										variant)) &&
									css`
										@media (max-width: 991px) {
											padding-top: 1.5rem;
										}
										@media (max-width: 540px) {
											padding-top: 1.85rem;
										}
									`,
								revampedAwardText &&
									companyName === "RateGenius" &&
									css`
										padding-top: 1.85rem;
										@media (min-width: 991px) {
											padding-bottom: 1.5rem;
										}
									`,
								isFirstCompany &&
									vertical === FINAL_EXPENSE_INSURANCE_PATH &&
									css`
										padding-top: 2.5rem;
										@media (min-width: 991px) {
											padding-bottom: 1rem;
										}
									`,
								(usesNewSoftwareDesign ||
									isProductListingPage ||
									[FLOWER_DELIVERY_PATH].includes(vertical)) &&
									tw`!mb-12 shadow-lg border-0 rounded-lg`,
								[CELL_PHONE_PLANS_PATH].includes(vertical) &&
									tw`!mb-4 shadow-lg border-0 rounded-lg`,
								usesSimplifiedDesign && tw`!mb-4 shadow-lg border-0 rounded-lg`,
							]}
							id={isFeatured ? `${companySlug}-featured` : companySlug}
						>
							{usesNonSoftwareCoupon && !couponReplacesReviewCta && (
								<div css={tw`hidden md:block`}>
									<Coupon companyIndex={companyIndex} />
								</div>
							)}
							<ServiceFlag companyIndex={companyIndex} />
							<ServiceLeftLayout
								company={company}
								companyIndex={companyIndex}
							/>
							<ServiceRightLayout
								isFeatured={isFeatured}
								company={company}
								companyIndex={companyIndex}
								expandServiceContent={this.expandServiceContent}
								serviceContentDisplayStyle={
									this.state.serviceContentDisplayStyle
								}
								serviceContentSelectorStyle={
									this.state.serviceContentSelectorStyle
								}
							/>
						</div>
					)}
				</Transition>
			</CompanyContext.Provider>
		);
	}
}

export default connect(mapStateToProps, null)(ServiceBlockLayout);

ServiceBlockLayout.propTypes = {
	isNotUserFiltered: PropTypes.bool.isRequired,
	companyIndex: PropTypes.number.isRequired,
	company: PropTypes.object.isRequired,
	companyName: PropTypes.string.isRequired,
	companySlug: PropTypes.string.isRequired,
	couponReplacesReviewCta: PropTypes.bool.isRequired,
	innerWidth: PropTypes.number.isRequired,
	vertical: PropTypes.string.isRequired,
	isServiceBlockLegacyStyled: PropTypes.bool.isRequired,
	variant: PropTypes.string,
	revampedAwardText: PropTypes.string,
	usesSoftwareDesign: PropTypes.bool.isRequired,
	usesNewSoftwareDesign: PropTypes.bool.isRequired,
	isProductListingPage: PropTypes.bool.isRequired,
	usesNonSoftwareCoupon: PropTypes.bool.isRequired,
	isFeatured: PropTypes.bool,
	usesSimplifiedDesign: PropTypes.bool.isRequired,
};
