import { IR_AUTO_INSURANCE_DEFAULT_PATH } from "@/config/site/insuranceranked";
import { getVertical } from "@/selectors";
import PropTypes from "prop-types";
import { prop } from "ramda";
import { useSelector } from "react-redux";

import AutoInsuranceModal from "./auto-insurance/AutoInsuranceModal";

const MultiStepModal = ({ appElement }) => {
	const vertical = useSelector(getVertical);

	const verticalMapping = {
		[IR_AUTO_INSURANCE_DEFAULT_PATH]: (
			<AutoInsuranceModal appElement={appElement} />
		),
	};

	const modal = prop(vertical, verticalMapping);

	return modal || null;
};

export default MultiStepModal;

MultiStepModal.propTypes = {
	appElement: PropTypes.string.isRequired,
};
