import * as constants from "@/config/constants";
import {
	customBehaviorByMobileTargetedPath,
	customBehaviorByPathsWithFilter,
} from "@/config/customBehaviorByPath";
import * as irConstants from "@/config/site/insuranceranked";
import { decode } from "he";
import queryString from "query-string";
import {
	__,
	curry,
	gt,
	gte,
	has,
	isEmpty,
	lensIndex,
	map,
	move,
	path,
	pathOr,
	pickBy,
	pipe,
	prop,
	set,
	where,
} from "ramda";
import { NumericFormat } from "react-number-format";
import { stripHtml } from "string-strip-html";

import {
	filterAdNetworkCompanies,
	filterEligibleAndGeoRelevantCompanies,
} from "./filters";
import { logError } from "./logError";

const MAX_STARS = 5;
const POOR_RATING = 1;
const FAIR_RATING = 2;
const AVERAGE_RATING = 3;
const GOOD_RATING = 4;
const ROUNDING_CEILING = 0.78;
const ROUNDING_FLOOR = 0.21;

export const returnImageURL = (assets, ASSET_ID) => {
	const assetContainingURL = assets.filter(
		(asset) => path(["sys", "id"], asset) === ASSET_ID,
	);
	return path(["0", "fields", "file", "url"], assetContainingURL);
};

export const filterCategoryInfoForDisplay = (categoryArray) =>
	categoryArray.filter((entry) =>
		pathOr(false, ["fields", "categoryName"], entry),
	);

export const determineIfFilteringAllowed = (isTargetedPath, vertical) => {
	const isTargetedPathFilteringAllowed = pathOr(
		false,
		[vertical, "isTargetedPathFilteringAllowed"],
		constants.SITE_INFORMATION,
	);
	const isNonTargetedPathFilteringAllowed = pathOr(
		false,
		[vertical, "isNonTargetedPathFilteringAllowed"],
		constants.SITE_INFORMATION,
	);
	const isFilteringAllowed = isTargetedPath
		? isTargetedPathFilteringAllowed
		: isNonTargetedPathFilteringAllowed;
	if (isFilteringAllowed === undefined) {
		throw new Error(
			`Unable to determine if filtering is allowed for vertical: ${vertical} when isTargetedPath: ${isTargetedPath}`,
		);
	}
	return isFilteringAllowed;
};

export const numToCurrency = (number, options = {}) => {
	const defaultOptions = {
		style: "currency",
		currency: "USD",
		minimumFractionDigits: 2,
	};

	/* Don't add decimals to whole numbers e.g. 4 -> $4 not 4 -> $4.00*/
	if (number % 1 === 0) {
		defaultOptions.minimumFractionDigits = 0;
	}
	const abbreviatedOptions = { notation: "compact", compactDisplay: "short" };
	const allOptions = { ...defaultOptions, ...abbreviatedOptions };
	const appliedOptions = options.abbreviate ? allOptions : defaultOptions;
	const currencyFormatter = new Intl.NumberFormat("en-US", appliedOptions);
	return currencyFormatter.format(number);
};

export const removeCharacterEntities = (containsEntities) => {
	if (Array.isArray(containsEntities))
		return containsEntities.map((str) => decode(str));
	else if (typeof containsEntities === "string")
		return decode(containsEntities);
	else
		throw new Error(
			"Neither a string or array of strings was passed to removeCharacterEntities helper function",
		);
};

export const replacePhoneNumbers = () => {
	if (window.Invoca) {
		window.Invoca.PNAPI.run();
	}
};

export const getFilterPlaceholder = (vertical, filter, filteringKey) => {
	const placeholder = path(
		[vertical, "filteringInformation", "placeholders", filter, filteringKey],
		constants.SITE_INFORMATION,
	);
	const defaultPlaceholder = path(
		[vertical, "filteringInformation", "placeholders", "DEFAULT", filteringKey],
		constants.SITE_INFORMATION,
	);
	if (!defaultPlaceholder)
		throw new Error(
			`defaultPlaceholder is undefined for vertical: ${vertical} and filteringKey: ${filteringKey} `,
		);
	return placeholder === undefined ? defaultPlaceholder : placeholder;
};

export const getMobileFilteringLabel = (vertical, title, value) => {
	const mobileFilteringButtonSelect = path(
		[vertical, "filteringInformation", "mobileFilteringButtonSelects"],
		constants.SITE_INFORMATION,
	).filter((filter) => filter.title === title);
	const targetedOption = mobileFilteringButtonSelect[0].options.filter(
		(opt) => opt.value.toString() === value,
	);
	return targetedOption[0].label;
};

export const resolveRatingKey = (
	vertical,
	urlPathFilter,
	filteringInformation,
	isMobileTargetedPath,
) => {
	const isNotUserFiltered =
		filteringInformation[constants.IS_NOT_USER_FILTERED];
	const isNotModalFiltered =
		filteringInformation[constants.IS_NOT_MODAL_FILTERED];

	/* boolean for determining if a vertical uses mobileRating */
	const isMobileRatingUsedForMobileTargetedPaths = pathOr(
		false,
		[vertical, "isMobileRatingUsedForMobileTargetedPaths"],
		constants.SITE_INFORMATION,
	);

	/* If a vertical implements sorting by mobileRating, browsing a mobile targeted path, and no filters have been applied*/
	if (
		isMobileRatingUsedForMobileTargetedPaths &&
		isMobileTargetedPath &&
		isNotUserFiltered &&
		isNotModalFiltered
	) {
		return "mobileRating";
	} else {
		// Default key for finding the rating of a company by urlPathFilter. Defaults to rating if it's not found
		const defaultOrUrlPathFilterRatingKey = pathOr(
			"rating",
			[constants.SITE, vertical, urlPathFilter, constants.CUSTOM_RATING_KEY],
			customBehaviorByPathsWithFilter,
		);

		const creditQuality = filteringInformation.creditQuality;
		const mortgageType = filteringInformation.mortgageType;
		const isAged = pathOr(
			false,
			[irConstants.AGED_FILTERING_KEY],
			filteringInformation,
		);
		const olderThan50FilteringKey = isAged
			? irConstants.AGED_FILTERING_KEY
			: undefined;
		const keyPath = creditQuality || olderThan50FilteringKey || mortgageType;

		return pathOr(
			defaultOrUrlPathFilterRatingKey,
			[
				constants.SITE,
				vertical,
				constants.CUSTOM_RATING_KEY_BY_UI_FILTERING,
				keyPath,
			],
			customBehaviorByPathsWithFilter,
		);
	}
};

const sortOrganic = (companies, ratingKey) =>
	companies.sort(
		(a, b) => path(["fields", ratingKey], b) - path(["fields", ratingKey], a),
	);

const applyPositionOverride = (companies, overrideCompanySlug) => {
	if (!overrideCompanySlug) {
		return companies;
	}
	const originalPosition = companies.findIndex(
		(company) => company.fields.companySlug === overrideCompanySlug,
	);
	if (originalPosition !== -1) {
		return move(originalPosition, 0, companies);
	}

	return companies;
};

const sortMediaAlphaCompanies = (companies, ratingKey) => {
	const mediaAlphaCompanies = companies.filter(
		(company) => company.fields.isMediaAlpha || company.fields.is_direct,
	); // sort direct partners in MA payload as if they're MA. Allows sorting by bid
	const organicCompanies = companies.filter(
		(company) => !company.fields.isMediaAlpha && !company.fields.is_direct,
	);
	const sortedMediaAlphaCompanies = mediaAlphaCompanies.sort(
		(a, b) => path(["fields", "bid"], b) - path(["fields", "bid"], a),
	);
	return [
		...sortedMediaAlphaCompanies,
		...sortOrganic(organicCompanies, ratingKey),
	];
};

/**
 * Main company sort function used when displaying company list
 * @returns
 */
export const sortCompanies = (
	vertical,
	filter,
	filteringInformation,
	companies,
	isMobileTargetedPath,
	sortCompaniesByMediaAlphaBid,
	companyPositionOverride,
) => {
	const ratingKey = resolveRatingKey(
		vertical,
		filter,
		filteringInformation,
		isMobileTargetedPath,
	);
	if (sortCompaniesByMediaAlphaBid) {
		return sortMediaAlphaCompanies(companies, ratingKey);
	}

	const organicallySortedCompanies = [...sortOrganic(companies, ratingKey)];
	const positionOverrideCompanies = applyPositionOverride(
		organicallySortedCompanies,
		companyPositionOverride,
	);

	// exclude any companies with rating of 0
	// useful in verticals where we have per-path rankings (ex. life insurance over 50 toggle)
	const companiesWithValidRating = positionOverrideCompanies.filter(
		(company) => path(["fields", ratingKey], company) > 0,
	);

	return companiesWithValidRating;
};

export const shouldFilteringModalRender = (
	isForceRendered,
	isTargetedPath,
	hasModalBeenClosed,
	isModalUrlParamMatched,
	vertical,
) => {
	if (typeof isTargetedPath !== "boolean") {
		throw new Error(
			"isTargetedPath parameter not supplied to shouldFilteringModalRender helper function or is not a boolean",
		);
	}
	if (typeof hasModalBeenClosed !== "boolean") {
		throw new Error(
			"hasModalBeenClosed parameter not supplied to shouldFilteringModalRender helper function or is not a boolean",
		);
	}
	if (typeof isModalUrlParamMatched !== "boolean") {
		throw new Error(
			"isModalUrlParamMatched parameter not supplied to shouldFilteringModalRender helper function or is not a boolean",
		);
	}
	if (!vertical) {
		throw new Error(
			"vertical parameter not supplied to shouldFilteringModalRender helper function",
		);
	}
	/*
	 * Since we're using the site and vertical specific configurations to determine if the filtering modal should be rendered and
	 * and it's currently disabled across all sites and verticals, we need a way to force render it for Storybook and Chromatic. Hence,
	 * returning true if isForceRendered === true
	 * */
	if (isForceRendered && !hasModalBeenClosed) return true;
	if (isTargetedPath) {
		/* Check if it should be rendered according to the site and vertical specific config when the modal url param is matched*/
		return (
			pathOr(
				false,
				[vertical, "isTargetedPathModalFilteringAllowed"],
				constants.SITE_INFORMATION,
			) &&
			isModalUrlParamMatched &&
			!hasModalBeenClosed
		);
	} else {
		/* On non-targeted where matching the modal url param doesn't apply, rendering is purely predicated on the vertical configuration */
		return (
			pathOr(
				false,
				[vertical, "isNonTargetedPathModalFilteringAllowed"],
				constants.SITE_INFORMATION,
			) && !hasModalBeenClosed
		);
	}
};

export const returnPaginationInformation = (
	location,
	vertical,
	isTargetedPath,
) => {
	if (!location) {
		throw new Error(
			"location parameter not passed to helper function parseQueryStringForPagination",
		);
	}

	if (!vertical) {
		throw new Error(
			"vertical parameter not passed to helper function parseQueryStringForPagination",
		);
	}

	const numberOfCompaniesToRenderKey = isTargetedPath
		? "targeted"
		: "nonTargeted";

	const paginationInformation = {
		/* Page prop starts at an index of 1 as opposed to 0 */
		page: 1,
		count: pathOr(
			constants.DEFAULT_NUMBER_OF_COMPANIES_TO_RENDER,
			[vertical, "numberOfCompaniesToRender", numberOfCompaniesToRenderKey],
			constants.SITE_INFORMATION,
		),
		isInvalidQuery: false,
		redirectLocationSearch: "",
		partialQueryObjectForPaginationClicks: {},
	};

	/* Create an object from location.search using query-string package */
	const partiallyParsedQueryObject = queryString.parse(location.search);

	/* Used for retaining query parameters after pagination navigation*/
	const queryObjectForPaginationNavigationLinks = {
		...partiallyParsedQueryObject,
	};
	/* We remove page and count properties as they are added via handlePageClick() in <CompanyList/>*/
	delete queryObjectForPaginationNavigationLinks.count;
	delete queryObjectForPaginationNavigationLinks.page;
	paginationInformation.partialQueryObjectForPaginationClicks =
		queryObjectForPaginationNavigationLinks;

	/* Predicate used to return a partial object copy containing only properties where the value is a number or can be converted to a number */
	const getNumericAndConvertibleToNumericProps = pickBy(
		(value) =>
			!Number.isNaN(Number(value)) &&
			typeof value === "string" &&
			value.trim() !== "",
	);
	/* Map over object and convert values to a number*/
	const convertToNumber = map((value) => Number(value));
	/* Left-to-right composition via pipe. Auto-curried so we're not passing any params*/
	const parseNumbers = pipe(
		getNumericAndConvertibleToNumericProps,
		convertToNumber,
	);
	const queryObject = parseNumbers(partiallyParsedQueryObject);

	/* Boolean is true when no query string is part of the url */
	const urlContainsQueryParams = !isEmpty(partiallyParsedQueryObject);

	const queryPredicate = where({
		page: gt(__, 0),
		count: gte(__, constants.MINIMUM_QUERY_STRING_COUNT_VALUE_FOR_PAGINATION),
	});

	/* Boolean indicating if a pagination query params are valid values */
	const validPaginationQueryParams = queryPredicate(queryObject);

	const queryHasPage = has("page");
	const queryHasCount = has("count");
	const queryHasPageAndCount =
		queryHasPage(queryObject) && queryHasCount(queryObject);

	/* If the pagination query params are valid use their values*/
	if (validPaginationQueryParams) {
		paginationInformation.page = queryObject.page;
		paginationInformation.count = queryObject.count;
	}

	/* If the URL contains query params that include 'page' and 'count' but their values are out of range, we redirect to a valid URL containing
	 * pagination query params that meet the predicate */
	if (
		urlContainsQueryParams &&
		queryHasPageAndCount &&
		!validPaginationQueryParams
	) {
		paginationInformation.isInvalidQuery = true;
		/* New object that will be used for invalid query redirects. Copy of the original so we retain query parameters*/
		const redirectQueryObject = { ...partiallyParsedQueryObject };
		/* Redefining page and count properties so they're values are the correct defaults */
		redirectQueryObject["page"] = paginationInformation.page;
		redirectQueryObject["count"] = paginationInformation.count;
		/* convert object to string for use by <Redirect> in <CompanyList>*/
		paginationInformation.redirectLocationSearch =
			queryString.stringify(redirectQueryObject);
	}

	return paginationInformation;
};

export const convertRatingToStars = (reviewRating) => {
	// rounds up if decimal place is >= 0.8
	// ROUNDING_CEILING is defined as .78 due to JS numbers being stored as 64 bit doubles; thus, 4.8 % 1 = 7.99999... instead of 0.8
	// Similarly, ROUNDING_FLOOR is defined as .21
	const roundFloorCheck = (reviewRating) => reviewRating % 1 < ROUNDING_CEILING;

	const roundedRating = roundFloorCheck(reviewRating)
		? Math.floor(reviewRating)
		: Math.ceil(reviewRating);
	const ratingRemainder = reviewRating - roundedRating;
	let colorQualities = [];

	let colorQuality;
	if (roundedRating <= POOR_RATING) {
		colorQuality = "poor";
	} else if (roundedRating <= FAIR_RATING) {
		colorQuality = "fair";
	} else if (roundedRating <= AVERAGE_RATING) {
		colorQuality = "average";
	} else if (roundedRating <= GOOD_RATING) {
		colorQuality = "good";
	} else {
		colorQuality = "excellent";
	}

	for (let i = 0; i < roundedRating; i++) {
		colorQualities.push(colorQuality);
	}

	// push half stars if decimal is between 0.3 and 0.7
	if (ratingRemainder > ROUNDING_FLOOR && ratingRemainder < ROUNDING_CEILING) {
		colorQualities.push(colorQualities[roundedRating - 1] + "Half");
	}

	// push default for any remaining indices or if rating is undefined
	if (colorQualities.length < MAX_STARS) {
		for (let i = colorQualities.length; i < MAX_STARS; i++) {
			colorQualities.push("default");
		}
	}

	return colorQualities;
};

export const resolveMainBenefitsField = (
	vertical,
	urlPathFilter,
	isMobileTargetedPath,
	filteringInformation,
	company,
) => {
	const { creditQuality, isNotModalFiltered, isNotUserFiltered } =
		filteringInformation;
	const isFilteringApplied = !isNotModalFiltered || !isNotUserFiltered;
	/* Function to make this more extensible/using different UI filters to dynamically change Company Main Benefits fields */
	const returnUiFilteringCompanyBenefitsFieldPath = (uiFilter) => [
		constants.SITE,
		vertical,
		constants.CUSTOM_MAIN_BENEFITS_FIELD_BY_UI_FILTERING,
		uiFilter,
	];
	const uiFilteredCompanyBenefitsFieldPath =
		returnUiFilteringCompanyBenefitsFieldPath(creditQuality);

	const urlPathFilteredCompanyBenefitsFieldPath = [
		constants.SITE,
		vertical,
		urlPathFilter,
		constants.CUSTOM_MAIN_BENEFITS_FIELD,
	];

	const mobileCompanyMainBenefitsField = [
		constants.SITE,
		vertical,
		constants.CUSTOM_MAIN_BENEFITS_FIELD,
	];

	const isMobileFieldDefinedInConfig = !!path(
		mobileCompanyMainBenefitsField,
		customBehaviorByMobileTargetedPath,
	);
	const isMobileFieldPresentInCompany = !!path(
		["fields", "mobileCompanyMainBenefits"],
		company,
	);

	// Use mobile benefits when applicable even when there's a url path filer e.g. mobileCompanyMainBenefits
	const shouldUseMobileBenefits =
		isMobileTargetedPath &&
		isMobileFieldDefinedInConfig &&
		isMobileFieldPresentInCompany;

	/*
  Fallback logic to use targeted slug for benefits field path when mobile can't be used (config doesn't exit or company doesn't have 'mobileCompanyMainBenefits')

  This logic means that mobileCompanyMainBenefits takes precedence over a custom field based on targeted slugs when applicable.

  An example would be `/personal-loans/a/bad-credit` where a company that has a mobileCompanyMainBenefits field with data will be used,
  otherwise it falls back to using the 'poorCreditCompanyMainBenefits'
  */
	const benefitsFieldPathByUrl = shouldUseMobileBenefits
		? mobileCompanyMainBenefitsField
		: urlPathFilteredCompanyBenefitsFieldPath;

	const customBehaviorObject = shouldUseMobileBenefits
		? customBehaviorByMobileTargetedPath
		: customBehaviorByPathsWithFilter;

	const benefitsFieldPath = isFilteringApplied
		? uiFilteredCompanyBenefitsFieldPath
		: benefitsFieldPathByUrl;
	return pathOr("companyMainBenefits", benefitsFieldPath, customBehaviorObject);
};

export const formatServiceRightColumns = (company, columnInformation) => {
	if (!company)
		throw new Error(
			"company not passed to formatServiceRightColumns helper function",
		);
	if (!columnInformation)
		throw new Error(
			"columnInformation not passed to formatServiceRightColumns helper function",
		);

	const generateColumn = (label, value) => ({ label, value });

	return columnInformation.map((column) => {
		const { fields, label } = column;

		/* Boolean to determine if we're using more than one Contentful field for a value */
		const isFieldsArray = Array.isArray(fields);

		const buildPath = (targetField) => ["fields", targetField];

		let contentfulFieldValues = isFieldsArray
			? fields.map((field) => path(buildPath(field), company))
			: path(buildPath(fields), company);

		/* Checking to see if we need to apply any conversions to contentfulField values before formatting */
		if (column.hasOwnProperty("preFormatterConversions")) {
			const { preFormatterConversions } = column;

			if (preFormatterConversions.hasOwnProperty("currencyConversions")) {
				const { currencyConversions } = preFormatterConversions;
				if (currencyConversions.isConverted) {
					contentfulFieldValues = numToCurrency(contentfulFieldValues, {
						abbreviate: prop("isAbbreviated", currencyConversions),
					});
				}
			}

			if (preFormatterConversions.hasOwnProperty("numbers")) {
				const { numbers } = preFormatterConversions;
				if (numbers.addCommaSeparator) {
					contentfulFieldValues = (
						<NumericFormat
							value={contentfulFieldValues}
							displayType={"text"}
							thousandSeparator={","}
							renderText={(value) => value}
						/>
					);
				}
			}
		}

		const fieldValues = column.formatter
			? column.formatter(contentfulFieldValues)
			: contentfulFieldValues;
		return generateColumn(label, fieldValues);
	});
};

export const getFallbackCompanies = (
	contentfulCompanies,
	vertical,
	sentryExceptionState,
) => {
	if (!vertical)
		throw new Error(
			"vertical not passed to ensureNonZeroCompanyCount helper function",
		);
	if (!contentfulCompanies)
		throw new Error(
			`No Contentful companies associated with vertical: ${vertical}. Every vertical needs at least 1 company`,
		);

	const fallbackCompaniesFilter = pipe(
		filterEligibleAndGeoRelevantCompanies, // company must be recommended and permitted in user's geo
		filterAdNetworkCompanies, // filter out unmatched ad network companies
	);
	const fallbackCompanies = fallbackCompaniesFilter(contentfulCompanies);
	if (fallbackCompanies.length === 0) {
		throw new Error(
			`Unable to render fallback companies. No eligible fallback companies for vertical: ${vertical}. There must be a recommended company with an organic partnership for every vertical.`,
		);
	} else {
		logError(
			`Fallback companies rendered for vertical: ${vertical}. This may have occurred for various reasons (user applied filtering, no MA company matches in payload, etc...)`,
		);
		return fallbackCompanies;
	}
};

export const formatMediaAlphaBenefits = (
	mediaAlphaBenefits,
	applyStringFormatting = false,
) => {
	/* case insensitive replacements e.g. "New york Life" would be replaced with "New York Life" */
	const replacements = [
		"Allstate",
		"Direct Auto",
		"Esurance",
		"Farmers",
		"Liberty Mutual",
		"Mercury Insurance",
		"New York Life",
		"Progressive",
		"Safe Driving Bonus®",
		"SelectQuote",
		"The General",
	];

	const states = [
		"Alabama",
		"Alaska",
		"American Samoa",
		"Arizona",
		"Arkansas",
		"California",
		"Colorado",
		"Connecticut",
		"Delaware",
		"District of Columbia",
		"Florida",
		"Georgia",
		"Guam",
		"Hawaii",
		"Idaho",
		"Illinois",
		"Indiana",
		"Iowa",
		"Kansas",
		"Kentucky",
		"Louisiana",
		"Maine",
		"Marshall Islands",
		"Maryland",
		"Massachusetts",
		"Michigan",
		"Minnesota",
		"Mississippi",
		"Missouri",
		"Montana",
		"Nebraska",
		"Nevada",
		"New Hampshire",
		"New Jersey",
		"New Mexico",
		"New York",
		"North Carolina",
		"North Dakota",
		"Northern Mariana Islands",
		"Ohio",
		"Oklahoma",
		"Oregon",
		"Palau",
		"Pennsylvania",
		"Puerto Rico",
		"Rhode Island",
		"South Carolina",
		"South Dakota",
		"Tennessee",
		"Texas",
		"Utah",
		"Vermont",
		"Virgin Island",
		"Virginia",
		"Washington",
		"West Virginia",
		"Wisconsin",
		"Wyoming",
	];

	const allReplacements = [...states, ...replacements];

	/* HTML string of list elements e.g. "<li>item 1</li><li>item 2</li>"*/
	const withoutUnorderedList = stripHtml(mediaAlphaBenefits, {
		onlyStripTags: ["ul", "ol"],
	}).result;
	/* Split into array of strings for any tag*/
	const withoutListTags = withoutUnorderedList
		.split(/<[^>]*>/g)
		.filter((val) => val !== "");

	const formatStrings = (str) => {
		const newWordsArray = [];
		/*  break string into array of words*/
		const allWordsArray = str.split(" ");

		/* Iterate through all words in the array*/
		for (let i = 0; i < allWordsArray.length; i++) {
			/* create a new array of letters for each word */
			const letterArray = [...allWordsArray[i]];

			if (i === 0) {
				// capitalize first letter of first word
				newWordsArray[i] = [
					...letterArray[0].toUpperCase(),
					...letterArray.slice(1),
				].join("");
			} else {
				/* check if making the word lower case changes anything. If so, part of it must be in caps*/
				if (allWordsArray[i].toLowerCase() !== allWordsArray[i]) {
					/* if there's a single capital letter return it in lower case */
					if (letterArray.length === 1) {
						newWordsArray[i] = allWordsArray[i].toLowerCase();
					} else {
						const isAcronym =
							letterArray[0].toUpperCase() === letterArray[0] &&
							letterArray[1].toUpperCase() === letterArray[1];
						if (isAcronym) {
							newWordsArray[i] = allWordsArray[i];
						} else newWordsArray[i] = allWordsArray[i].toLowerCase();
					}
				} else {
					newWordsArray.push(allWordsArray[i].toLowerCase());
				}
			}
		}

		const applyReplacements = (str) => {
			let nextString = str;
			allReplacements.forEach((word) => {
				const regEx = new RegExp(word, "ig");
				nextString = nextString.replace(regEx, word);
			});
			return nextString;
		};

		const partiallyFormattedString = newWordsArray.join(" ");
		const completelyFormattedString = removeCharacterEntities(
			applyReplacements(partiallyFormattedString),
		);
		return completelyFormattedString;
	};

	if (applyStringFormatting) {
		return withoutListTags.map((str) => formatStrings(str.trim()));
	} else return removeCharacterEntities(withoutListTags);
};

export const swap = curry((index1, index2, list) => {
	if (
		index1 < 0 ||
		index2 < 0 ||
		index1 > list.length - 1 ||
		index2 > list.length - 1
	) {
		return list; // index out of bound
	}
	const value1 = list[index1];
	const value2 = list[index2];
	return pipe(
		set(lensIndex(index1), value2),
		set(lensIndex(index2), value1),
	)(list);
});
