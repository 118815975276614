import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { path } from "ramda";
import { useSelector } from "react-redux";
import tw from "twin.macro";

import {
	BB_DOMAIN,
	BTR_DOMAIN,
	BW_DOMAIN,
	FVY_DOMAIN,
	SITE,
	TCR_DOMAIN,
} from "../../../config/constants";
import { CREDIT_ASSISTANCE_PATH } from "../../../config/site/bestcreditreviews";
import {
	DISABILITY_INSURANCE_DEFAULT_PATH,
	IR_LIFE_INSURANCE_DEFAULT_PATH,
} from "../../../config/site/insuranceranked";
import {
	AUTO_REFINANCE_PATH,
	BUSINESS_LOANS_DEFAULT_PATH,
	PERSONAL_LOANS_DEFAULT_PATH,
} from "../../../config/site/thecreditreview";
import FilteringContainer from "../../containers/FilteringContainer";
import { useInnerWidth } from "../../hooks/useInnerWidth";
import { useVariations } from "../../links/useVariations";
import {
	getIsTransitionApplied,
	getUsesSimplifiedDesign,
	getVertical,
} from "../../selectors";
import { CompanyList } from "../CompanyList/CompanyList";
import { IndividualVerticalFilteringContainer } from "../Filtering/IndividualVerticalFilteringContainer";
import { ExitIntentModalContainer } from "../Modals/ExitIntentModalContainer";
import FilteringModal from "../Modals/FilteringModal";
import MobileFilteringModal from "../Modals/MobileFilteringModal";
import MultiStepModal from "../Modals/MultiStep/MultiStepModal";
import MediaAlphaBanner from "./MediaAlphaBanner";

export const MainLayout = ({ deviceType }) => {
	useVariations();
	const innerWidth = useInnerWidth(deviceType);
	const isTransitionApplied = useSelector(getIsTransitionApplied);
	const transitionCss =
		isTransitionApplied === true ? styles.transition : undefined;

	const vertical = useSelector(getVertical);
	const usesSimplifiedDesign = useSelector(getUsesSimplifiedDesign);

	const containerClasses = [
		"container",
		DISABILITY_INSURANCE_DEFAULT_PATH && "lg2:tw-max-w-1170px",
		vertical === IR_LIFE_INSURANCE_DEFAULT_PATH && "lg2:tw-max-w-[1140px]",
		SITE === FVY_DOMAIN && "tw-px-4 xl:tw-px-0",
		SITE === BTR_DOMAIN &&
			"!tw-max-w-[500px] tw-px-4 lg:!tw-max-w-none xl:tw-px-0",
		(vertical === CREDIT_ASSISTANCE_PATH || SITE === BW_DOMAIN) &&
			"tw-mx-auto tw-max-w-[1142px] tw-px-4 lg2:tw-max-w-[1142px] xl:tw-px-4",
		SITE === BB_DOMAIN &&
			"tw-mx-auto tw-w-[550px] tw-px-4 lg:tw-w-[1142px] lg:tw-max-w-none lg2:tw-w-[1142px]",
	]
		.filter(Boolean)
		.join(" ");

	return (
		<div>
			<MediaAlphaBanner />
			<FilteringContainer innerWidth={innerWidth} />
			<IndividualVerticalFilteringContainer />
			<div
				className={containerClasses}
				css={[
					usesSimplifiedDesign && SITE !== FVY_DOMAIN && tw`!max-w-[1110px]`,
					path([SITE, vertical], styles),
					transitionCss,
				]}
			>
				<ExitIntentModalContainer />
				<FilteringModal appElement={"app"} />
				<MobileFilteringModal appElement={"app"} />
				<MultiStepModal appElement={"app"} />
				<CompanyList innerWidth={innerWidth} />
			</div>
		</div>
	);
};

MainLayout.propTypes = {
	deviceType: PropTypes.string,
};

const styles = {
	transition: css`
		opacity: 0.5;
		transition: opacity 100ms ease-in-out;
	`,
	[TCR_DOMAIN]: {
		[AUTO_REFINANCE_PATH]: tw`md:mt-20px`,
		[BUSINESS_LOANS_DEFAULT_PATH]: tw`mt-0 md:mt-20px`,
		[PERSONAL_LOANS_DEFAULT_PATH]: tw`mt-0 md:mt-20px`,
	},
};
