import { useContext } from "react";
import PropTypes from "prop-types";

import { CompanyContext } from "../CompanyList/CompanyContext";

export const BaseAmazonDiscount = (props) => {
	const { company } = useContext(CompanyContext);

	const couponText = company?.fields?.couponText
		? `${company.fields.couponText} Coupon`
		: undefined;
	const discountText = company?.fields?.discount
		? `${Math.floor(company.fields.discount)}% Off`
		: undefined;

	if (!couponText && !discountText) return null;

	const displayText =
		couponText && discountText
			? `${couponText} + ${discountText}`
			: couponText || discountText;

	return (
		<div className={props?.wrapper}>
			<p className={props?.text}>{displayText}</p>
		</div>
	);
};

BaseAmazonDiscount.propTypes = {
	wrapper: PropTypes.string,
	text: PropTypes.string,
};
