import * as constants from "@/config/constants";
import {
	CELL_PHONE_PLANS_PATH,
	FLOWER_DELIVERY_PATH,
	INCORPORATION_SERVICES_PATH,
	WINE_CLUBS_PATH,
} from "@/config/site/favy";
import * as irConstants from "@/config/site/insuranceranked";
import * as tcrConstants from "@/config/site/thecreditreview";
import * as selectors from "@/selectors";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { path, propOr } from "ramda";
import { useSelector } from "react-redux";
import tw from "twin.macro";

import Promotion from "../../general/Promotion";
import SocialProof from "../../general/SocialProof";
import Coupon from "../Coupon";
import ReviewWidgetSection from "../ServiceLeft/ReviewWidgetSection";
import PhoneLink from "./PhoneLink";
import Price from "./Price";
import ReviewLink from "./ReviewLink";
import VisitLink from "./VisitLink";

const styles = {
	footer: {
		legacy: css`
			margin-top: 15px;
			justify-content: space-between;

			@media (max-width: 1199px) {
				flex-wrap: wrap;
			}

			@media (max-width: 767px) {
				width: 100%;
				margin-top: 10px;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			@media (max-width: 500px) {
				margin-top: 0;
				max-width: 100%;
			}

			@media (max-width: 400px) {
				flex-direction: column;
			}
		`,
		revamped: css`
			@media (max-width: 1199px) {
				margin-left: 15px;
			}

			@media (max-width: 991px) {
				width: 100%;
				margin-top: 10px;
				margin-left: 0;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			@media (max-width: 767px) {
				margin-top: 0px;
			}

			@media (max-width: 580px) {
				margin-left: 0px;
			}

			@media (max-width: 500px) {
				flex-wrap: wrap;
				max-width: 100%;
			}
		`,
		twoButtons: css`
			@media (max-width: 991px) {
				display: inline-flex;
				justify-content: space-around;
				margin-top: 10px;
			}

			@media (max-width: 500px) {
				flex-direction: column;
			}
		`,
		usesSoftwareDesign: css`
			width: 100%;
		`,
		domainLevelOverrides: {
			[constants.TCR_DOMAIN]: {
				[tcrConstants.BUSINESS_LOANS_DEFAULT_PATH]: css`
					@media (max-width: 1199px) {
						max-width: 138px;
					}

					@media (max-width: 991px) {
						max-width: 100%;
					}
				`,
			},
			[constants.FVY_DOMAIN]: {
				[FLOWER_DELIVERY_PATH]: css`
					@media (min-width: 992px) {
						max-width: 200px;
					}
				`,
				[CELL_PHONE_PLANS_PATH]: css`
					@media (min-width: 992px) {
						max-width: 200px;
						margin-left: 2rem;
					}
				`,
			},
		},
	},

	reviewAndVisitLinkWrapper: {
		legacy: css`
			max-width: 380px;
			transition: none;
			width: 100%;

			/*ie11 styling correction*/
			@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
				flex-direction: column;
			}

			@media (max-width: 767px) {
				max-width: 150px;
				width: 100%;
				margin-left: 0;
				margin-right: 0;
			}

			@media (max-width: 500px) {
				margin-bottom: 0;
			}

			@media (max-width: 450px) {
				max-width: 150px;
			}
		`,
		revamped: {
			baseStyles: css`
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				max-width: 690px;
				transition: none;
				width: 100%;

				/*ie11 styling correction*/
				@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
					flex-direction: column;
				}

				@media (max-width: 991px) {
					align-items: center;
					justify-content: space-evenly;
					flex-direction: row-reverse;
				}

				@media (max-width: 767px) {
					width: 100%;
					margin-left: 0;
					margin-right: 0;
					margin-top: 15px;
				}

				@media (max-width: 580px) {
					flex-direction: column;
				}

				@media (max-width: 500px) {
					margin-bottom: 0;
				}
			`,
			usesSoftwareDesign: css`
				max-width: unset;

				@media (min-width: 991px) {
					margin-top: 2rem;
				}
			`,
			twoButtons: css`
				@media (max-width: 991px) {
					width: 175px;
					margin-top: 0;
				}
			`,
			domainLevelOverrides: {
				[constants.FVY_DOMAIN]: {
					[WINE_CLUBS_PATH]: css`
						@media (min-width: 991px) {
							width: min-content;
						}
					`,
				},
			},
		},
	},
	irOneOffStyleOverride: css`
		color: #1d8ce0;
		font-size: 14px;
		display: table;
		font-weight: 600;
		margin-top: 10px;
	`,
};

const ServiceRightFooter = ({ company, companyIndex }) => {
	const vertical = useSelector(selectors.getVertical);
	const isTargetedPath = useSelector(selectors.getIsTargetedPath);
	const isOnlyAllowedToRenderVisitLinks = useSelector(
		selectors.getIsOnlyAllowedToRenderVisitLinks,
	);
	const isServiceBlockLegacyStyled = useSelector(
		selectors.getIsServiceBlockLegacyStyled,
	);
	const isVisitLinkingProhibited = useSelector((state) =>
		selectors.getIsVisitLinkingProhibited(state, { company }),
	);
	const isAllLinkingProhibited = useSelector((state) =>
		selectors.getIsAllLinkingProhibited(state, { company }),
	);
	const usesSoftwareDesign = useSelector(selectors.getUsesSoftwareDesign);
	const softwareDesignWithoutReviewLink = useSelector(
		selectors.getPreventRenderingSoftwareReviewLink,
	);
	const usesNonSoftwareCoupon = useSelector((state) =>
		selectors.getUsesNonSoftwareCoupon(state, { company }),
	);
	const defaultLink = isOnlyAllowedToRenderVisitLinks ? null : (
		<ReviewLink company={company} companyIndex={companyIndex} />
	);
	const linkTextOverrideMap = {
		Bestow: "Ages 18-54 Only",
		Sproutt: "Income Over $50k",
	};
	const companyName = useSelector((state) =>
		selectors.getCompanyName(state, { company }),
	);
	const linkText = propOr("100% Free Quote", companyName, linkTextOverrideMap);
	const irOneOffLink = (
		<VisitLink
			company={company}
			companyIndex={companyIndex}
			linkTextOverride={linkText}
			styleOverride={styles.irOneOffStyleOverride}
		/>
	);
	const useIrOneOffLink =
		constants.SITE === constants.IR_DOMAIN &&
		(vertical === irConstants.IR_LIFE_INSURANCE_DEFAULT_PATH ||
			irConstants.IR_PET_INSURANCE_DEFAULT_PATH) &&
		isTargetedPath;
	const link = useIrOneOffLink ? irOneOffLink : defaultLink;
	/* TODO remove one-off logic once IR content is 100% compliant. See https://applieddev.atlassian.net/browse/FUID-218 */
	const twoButtonsRendered =
		useSelector((state) =>
			selectors.getAreTwoServiceRightFooterButtonsRendered(state, { company }),
		) && !isServiceBlockLegacyStyled;
	const usesNewSoftwareDesign = useSelector(selectors.getUsesNewSoftwareDesign);
	const couponReplacesReviewCta = useSelector(
		selectors.getCouponReplacesReviewCta,
	);
	const isProductListingPage = useSelector(selectors.getIsProductListingPage);
	const usesSimplifiedDesign = useSelector(selectors.getUsesSimplifiedDesign);

	/* Unlike some other components that render placeholder anchors when all linking is prohibited, ServiceRightFooter renders no child components or elements.
	 * Mostly because it normally renders buttons, CTA elements, etc... that could potentially confuse a user if rendered but not functional  */
	return isAllLinkingProhibited ? null : (
		<div
			css={[
				isServiceBlockLegacyStyled
					? styles.footer.legacy
					: styles.footer.revamped,
				twoButtonsRendered && styles.footer.twoButtons,
				usesSoftwareDesign && styles.footer.usesSoftwareDesign,
				usesNewSoftwareDesign && tw`h-full !mt-0 !flex !items-center`,
				path(
					["footer", "domainLevelOverrides", constants.SITE, vertical],
					styles,
				),
				usesNonSoftwareCoupon && tw`flex-col`,
				isProductListingPage &&
					tw`bg-[#F5F5F5] lg:h-full w-[35%] items-center justify-evenly flex flex-row !flex-nowrap lg:p-0 px-2 py-6 lg:flex-col`,
				usesSimplifiedDesign && tw`md:flex-row !ml-0`,
			]}
		>
			{usesNonSoftwareCoupon && !couponReplacesReviewCta && (
				<Coupon company={company} companyIndex={companyIndex} />
			)}
			{isProductListingPage && <Price company={company} />}
			<PhoneLink company={company} companyIndex={companyIndex} />
			{usesSimplifiedDesign && (
				<div
					css={tw`md:flex lg:hidden items-center justify-center lg:mr-4 w-full hidden`}
				>
					<Promotion company={company} companyIndex={companyIndex} />
				</div>
			)}
			{!isVisitLinkingProhibited && (
				<div
					css={[
						isServiceBlockLegacyStyled
							? styles.reviewAndVisitLinkWrapper.legacy
							: styles.reviewAndVisitLinkWrapper.revamped.baseStyles,
						twoButtonsRendered &&
							styles.reviewAndVisitLinkWrapper.revamped.twoButtons,
						usesSoftwareDesign &&
							styles.reviewAndVisitLinkWrapper.revamped.usesSoftwareDesign,
						softwareDesignWithoutReviewLink && tw`flex justify-start !mt-4`,
						path(
							[
								"reviewAndVisitLinkWrapper",
								"revamped",
								"domainLevelOverrides",
								constants.SITE,
								vertical,
							],
							styles,
						),
						usesNewSoftwareDesign &&
							tw`h-[50%] lg:min-h-[200px] flex-wrap sm:flex-nowrap w-full flex justify-around !mt-0 lg:!items-center lg:!content-center lg:!flex-col lg:!justify-evenly`,
						isProductListingPage && tw`!mt-0`,
						usesSimplifiedDesign &&
							tw`lg:!justify-center lg:w-auto md:w-full !flex-col`,
					]}
				>
					{usesSimplifiedDesign && (
						<SocialProof company={company} companyIndex={companyIndex} />
					)}
					<VisitLink
						company={company}
						companyIndex={companyIndex}
						linkTextOverride={
							vertical === INCORPORATION_SERVICES_PATH ? "Visit Site" : null
						}
					/>
					{couponReplacesReviewCta && !usesSimplifiedDesign && (
						<Coupon company={company} companyIndex={companyIndex} />
					)}
					{!couponReplacesReviewCta && !usesSimplifiedDesign && link}

					{usesNewSoftwareDesign && !usesSimplifiedDesign && (
						<div css={tw`hidden lg:flex`}>
							<ReviewWidgetSection company={company} />
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default ServiceRightFooter;

ServiceRightFooter.propTypes = {
	company: PropTypes.object.isRequired,
	companyIndex: PropTypes.number.isRequired,
	companyVisitPath: PropTypes.any,
};
