import { Icon } from "../../../../components/BaseComponents/Icon";

export const CloseIcon = (props) => (
	<Icon viewBox="0 0 18 18" fill="none" {...props}>
		<path
			d="M13.242 3.343L9 7.586 4.758 3.343a.5.5 0 00-.707 0l-.708.707a.5.5 0 000 .707L7.586 9l-4.243 4.242a.5.5 0 000 .707l.707.708a.5.5 0 00.707 0L9 10.414l4.242 4.243a.5.5 0 00.707 0l.708-.707a.5.5 0 000-.707L10.414 9l4.243-4.242a.5.5 0 000-.707l-.707-.708a.5.5 0 00-.707 0z"
			fill="currentColor"
		/>
	</Icon>
);
