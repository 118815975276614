import { useSelector } from "react-redux";

import { getVertical, sortedCompaniesSelector } from "../../selectors";
import { IncorporationServicesExitModal } from "./ExitModalsByVertical/IncorporationServicesExitModal";

const VERTICAL_MODAL_MAPPING = {
	"incorporation-services": IncorporationServicesExitModal,
};

export const ExitIntentModalContainer = () => {
	const vertical = useSelector(getVertical);
	const companies = useSelector(sortedCompaniesSelector);

	const topCompany = companies[0];

	const ModalComponent = VERTICAL_MODAL_MAPPING[vertical];
	if (ModalComponent) {
		return <ModalComponent company={topCompany} />;
	}

	return null;
};
