import PropTypes from "prop-types";
import { Route, Routes } from "react-router";

import { ROUTE_PATHS } from "../../../config/constants";

export const RoutesProvider = ({ children }) => (
	<Routes>
		{ROUTE_PATHS.map((route, idx) => (
			<Route key={idx} path={route.path} element={children} />
		))}
	</Routes>
);

RoutesProvider.propTypes = {
	children: PropTypes.node.isRequired,
};
