import { getVertical } from "@/selectors";
import PropTypes from "prop-types";
import { Button, Dialog, Heading, Modal } from "react-aria-components";
import { useSelector } from "react-redux";

import { CloseIcon } from "../../../client/assets/icons/common/CloseIcon";
import { BaseCompanyLogo } from "../../BaseComponents/BaseCompanyLogo";
import { BaseExitModalProvider } from "../../BaseComponents/BaseExitModalProvider";
import { BaseLinkWrapper } from "../../BaseComponents/BaseLinkWrapper";

export const IncorporationServicesExitModal = ({ company }) => {
	const verticalSlug = useSelector(getVertical);

	if (company.fields.companySlug !== "tailor-brands") {
		return null;
	}

	return (
		<BaseExitModalProvider
			vertical={verticalSlug}
			topCompanySlug={company.fields.companySlug}
			className={({ isEntering, isExiting }) =>
				`tw-fixed tw-inset-0 tw-z-[9999] tw-flex tw-min-h-full tw-items-center tw-justify-center tw-overflow-y-auto tw-bg-black/25 tw-px-4 tw-text-center tw-backdrop-blur ${isEntering ? "tw-duration-300 tw-ease-out tw-animate-in tw-fade-in" : ""} ${isExiting ? "tw-duration-200 tw-ease-in tw-animate-out tw-fade-out" : ""} `
			}
		>
			<Modal
				className={({ isEntering, isExiting }) =>
					`tw-w-full tw-max-w-[328px] tw-overflow-hidden tw-rounded-[12px] tw-bg-white md:tw-max-w-[420px] lg:tw-max-w-[810px] lg:tw-rounded-none ${isEntering ? "tw-duration-300 tw-ease-out tw-animate-in tw-zoom-in-95" : ""} ${isExiting ? "tw-duration-200 tw-ease-in tw-animate-out tw-zoom-out-95" : ""} `
				}
			>
				<Dialog className="tw-relative tw-outline-none">
					{({ close }) => (
						<>
							<Button
								onPress={close}
								aria-label="Dismiss"
								data-testid="exit-modal-dismiss-button"
								className="tw-absolute tw-right-0 tw-top-0 tw-inline-flex tw-cursor-pointer tw-select-none tw-appearance-none tw-items-center tw-whitespace-nowrap tw-rounded tw-border-none tw-bg-transparent tw-p-2 tw-align-middle tw-text-black tw-outline-none tw-transition-all focus-visible:tw-ring focus-visible:tw-ring-blue-500/50 disabled:tw-cursor-not-allowed md:tw-gap-2"
							>
								<CloseIcon className="tw-text-xl" />
							</Button>
							<BaseLinkWrapper extraQueryParams={{ fromExitModal: 1 }}>
								<div className="tw-flex">
									<div className="tw-hidden tw-h-auto tw-max-w-[394px] lg:tw-block">
										<img
											src="/favy/images/exit-modal-inc-serv-bg.jpg"
											alt="Woman"
											className="tw-size-full tw-object-cover"
										/>
									</div>
									<div className="tw-flex tw-w-full tw-flex-col tw-items-center tw-px-4 tw-pb-6 tw-pt-8 md:tw-p-8">
										<BaseCompanyLogo
											classNames={{
												picture: "tw-mb-4 tw-h-[54px]",
												image: "tw-size-full tw-object-cover",
											}}
										/>
										<img
											src="/favy/images/exit-modal-inc-serv-gift.png"
											alt="Gold Coin"
											className="tw-w-full"
										/>
										<div className="tw-my-4">
											<div className="tw-rounded-lg tw-border-[1px] tw-border-dashed tw-border-[#E1255B]">
												<p className="tw-px-4 tw-py-2 tw-text-base tw-font-semibold tw-leading-6 tw-tracking-[-0.16px] tw-text-[#E1255B]">
													35% OFF LLC Plans
												</p>
											</div>
										</div>
										<Heading
											slot="title"
											className="tw-mb-2 tw-text-2xl tw-font-bold tw-leading-[normal] tw-tracking-[-0.24px] tw-text-[#131D2B] md:tw-mb-1 md:tw-text-[32px] md:tw-leading-[38px] md:tw-tracking-[-0.64px]"
										>
											Form an LLC in a Few Minutes For $0
										</Heading>
										<div className="tw-mb-6 tw-flex tw-gap-2 md:tw-mb-8"></div>
										<div className="tw-mb-4">
											<p className="tw-text-[20px] tw-font-medium tw-leading-7 tw-tracking-[-0.2px] tw-text-[#131D2B]">
												Starting at $0 (Plus State Fees)
											</p>
										</div>
										<Button
											className="tw-mb-4 tw-flex tw-w-full tw-items-center tw-justify-center tw-gap-2 tw-rounded-lg tw-border-0 tw-bg-[#16A34A] tw-px-4 tw-py-3 tw-text-white tw-transition-colors hover:tw-cursor-pointer hover:tw-bg-[#0b7032]"
											onPress={close}
											data-testid="cta-button"
										>
											<span className="tw-text-base tw-font-semibold tw-leading-6 tw-tracking-[-0.16px] md:tw-text-xl md:tw-leading-8">
												Get Started
											</span>
										</Button>
										<img
											src="/favy/images/exit-modal-trustpilot-ratings.png"
											alt="Trustpilot Rating"
											className="tw-w-full"
										/>
									</div>
								</div>
							</BaseLinkWrapper>
						</>
					)}
				</Dialog>
			</Modal>
		</BaseExitModalProvider>
	);
};

IncorporationServicesExitModal.propTypes = {
	company: PropTypes.object,
};
